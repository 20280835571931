import {useMutation, useQuery} from "@apollo/client";

import {message} from "antd";
import {SELECTED_QUESTIONS} from "../apollo/queries/questions";
import {UPDATE_QUESTION} from "../apollo/mutations/questions";


function useSelectedQuestions() {
  const {data, loading} = useQuery(SELECTED_QUESTIONS, {
    variables: {query: {event: {_id:"62021920da66518b98fdb9b6"}, state:"selected"}},
    pollInterval: 2000
  })
  return {
    questions: data?.questions ?? [],
    loading
  }
}

function useUpdateQuestion() {
  const [updateOneQuestion, {loading, error}] = useMutation(UPDATE_QUESTION)
  console.log(error)
  
  async function updateQuestion(_id, state) {
    try {
      await updateOneQuestion({
        variables: {
          query: {_id},
          set: {state}
        },
        refetchQueries: [
          {
            query: SELECTED_QUESTIONS,
            variables: {query: {event: {_id: "62021920da66518b98fdb9b6"}, state:"selected"}}
          },
        ]
      })
      message.success("La question a été mise à jour!")
    } catch (e) {
      console.log(e)
      message.error("La question n'a pas été mise à jour!")
    }
    
  }
  
  return {
    updateQuestion,
    loading
  }
}


export {
  useSelectedQuestions,
  useUpdateQuestion,
}