import {gql} from "@apollo/client";

const UPDATE_QUESTION = gql`
    mutation updateOneQuestion($set:QuestionUpdateInput!,$query:QuestionQueryInput){
        updatedQuestion:updateOneQuestion(set:$set,query:$query){
            _id
        }
    }
`
const DELETE_QUESTION=gql`
    mutation deleteOneQuestion($query:QuestionQueryInput!){
        deletedQuestion:deleteOneQuestion(query:$query){
            _id
        }
    }
`

export {UPDATE_QUESTION,DELETE_QUESTION}