import {gql} from "@apollo/client";

const QUESTIONS = gql`
    query questionsViews($query: QuestionsViewQueryInput) {
        questionsViews(query:$query) {
            communication
            questions{
                _id
                fullName
                state
                content
            }
        }
    }
`;

const SELECTED_QUESTIONS=gql`
    query questions($query: QuestionQueryInput) {
        questions(query:$query) {
            _id
            content
            state
        }
    }
`;
export {QUESTIONS,SELECTED_QUESTIONS}