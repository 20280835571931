import React from 'react';
import {Button, List, Card, Typography} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {useSelectedQuestions, useUpdateQuestion} from "../hooks/questions";
const {Title}=Typography

export default function SelectedQuestions({_id}) {
  const {questions} = useSelectedQuestions(_id,"selected")
  const {updateQuestion,loading:updateLoading}=useUpdateQuestion(_id)
  function render(item) {
    return <List.Item
      actions={[
        <Button
          type="primary"
          loading={updateLoading}
          icon={<DownloadOutlined/>}
          onClick={()=>updateQuestion(item._id,"archived")}
        >
          Archiver
        </Button>
      ]}
    >
      <List.Item.Meta
        title={item.content}
      />
    </List.Item>
    
  }
  
  return (
    <Card
      style={{minHeight:"90vh",margin:30}}
      title={<Title level={2}>Liste des question</Title>}
    >
      <List
        itemLayout="horizontal"
        dataSource={questions}
        renderItem={render}
      />
    </Card>
   
  );
}